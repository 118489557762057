import React from 'react'

function Pnf() {
  return (
    <div className="container">
        <div className="row">
            <div className="col-md-12 text-center">
                {/* <h3 className="display-3 text-danger">Requested path not found</h3>
                <h4 className="display-4 text-warning">404 Error</h4> */}
                <img src="https://media4.giphy.com/media/FUKCPzVj0GGrCsdsmP/giphy.gif?cid=ecf05e47j0irqc76asr9a4f08vtnrupsepl7rf73jwwaohzw&rid=giphy.gif&ct=g" alt="404 Error" className='img-fluid' />
            </div>
        </div>
    </div>
  )
}

export default Pnf
